<template>
  <v-row>
    <v-col 
      cols="12" 
      class="pb-0"
    >
      <div class="text-left">
        <label class="text text-m-small-bold">Email</label>
        <v-text-field
          ref="emailField"
          v-model="email"
          class="field-background-white"
          height="56"
          outlined
          placeholder="Enter your email"
          :rules="[rules.required, rules.email]"
          required
        />
      </div>
    </v-col>
    <v-row>
      <v-col 
        cols="12"
        class="actions"
      >
        <v-card-actions class="container-actions">
          <v-btn
            ripple
            block
            large
            color="primary"
            class="heading heading-s-small"
            @click="sendEmail"
          >
            Send email
          </v-btn>
        </v-card-actions>
        <v-card-actions class="container-actions">
          <v-btn
            ripple
            block
            large
            color="white"
            class="heading heading-s-small shadow-light-button"
            @click="cancelButton"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import ValidatorComponent from '@/components/common/ValidatorComponent';
import { mapActions } from "vuex";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ValidatorComponent },
  data: () => ({
    email: null,
    emailExist: false,
    rules: ValidatorComponent.data().rules,
    TAB_LOGIN: 0,
  }),
  methods: {
    ...mapActions('auth', ['emailExistsForResetPassword', 'sendPasswordReset']),
    async sendEmail() {
      if (!this.email) {
        this.$toasted.global.showError({message: "The email field is required" });
      } else {
        try {
          const emailExistsResponse = await this.emailExistsForResetPassword({ email: this.email });
          if (!emailExistsResponse.data.exists) return;
  
          const passwordResetResponse = await this.sendPasswordReset({ email: this.email });
  
          this.$toasted.global.showSuccess({message: passwordResetResponse.data.message});
          this.$emit("goBackToLogin", this.TAB_LOGIN);
    
        } catch (error) {
          this.$toasted.global.showError({message: error.response.data?.message || error.message});
        }
      }
    },
    cancelButton() {
      this.$emit("goBackToLogin", this.TAB_LOGIN);
    }
  }
}
</script>
<style scoped>
  .v-text-field--outlined >>> fieldset {
    border: 1px solid var(--v-grey-darken4);
    background-color: transparent !important;
  }
  
  .field-background-white >>> input::placeholder {
    color: var(--v-grey-darken4) !important;
  }

  .v-text-field--outlined.v-input--has-state >>> fieldset {
    border: 2px solid currentColor;
  }

  .actions {
    padding: 18px;
  }

  .container-actions {
    flex-wrap: wrap;
    bottom: 15px;
    margin-left: 0px;
    width: 100%;
  }
  @media (min-width: 1440px)  and (orientation: landscape) {
    .actions {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
</style>