<template>
  <ContainerComponent
    :items="items"
    :type="type"
    :tab="itemTab"
  >
    <template #right>
      <v-flex
        fill-height
        class="px-3 px-md-4 pt-3 pt-md-5 pt-lg-7 px-lg-6 text-center "
      >
        <v-tabs
          v-show="false"
          v-model="tab"
          grow
          dark
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="fill-height background"
          touchless
        >
          <v-form
            ref="auth_form"
            class="fill-height"
            action="/login"
            method="post"
            lazy-validation
            @submit.prevent="submit"
          >
            <slot name="token" />
            <v-tab-item
              v-for="item in items"
              :key="item.tab"
              class="fill-height"
            >
              <v-row class="text-left">
                <v-col class="lg-2 sm-3 pb-0">
                  <h3
                    class="primary--text text-lg-center text-sm-center mb-lg-2 mb-md-0 mb-1"
                    :class="titleResponsiveSize"
                  >
                    {{ item.title }}
                  </h3>
                  <h3
                    v-if="item.tab > enumOptions.mainView && item.tab!=enumRegister.confirmCode"
                    class="text text-l-large-regular text-sm-center mb-lg-2 mb-md-0 mb-1"
                  >
                    {{ item.content }}
                  </h3>
                  <h3
                    v-if="checkHasToShowMaskedPhoneNumber(item)"
                    class="text text-l-large-regular text-sm-center mb-lg-2 mb-md-0 mb-1"
                  >
                    {{ item.content + showPhoneNumberLastDigits }}
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <EmailPasswordComponent
                    v-if="item.slot == 'email-password'"
                    :is-new-email="true"
                    :email="form.email"
                    @email="emailChange"
                    @password="passwordChange"
                  />
                  <ForgotPasswordComponent 
                    v-if="item.slot == 'password-reset'"
                    @password-reset-email-sent="passwordReset"
                    @goBackToLogin="goBackToLogin"
                  />
                  <PhoneComponent
                    v-if="item.slot == 'phone'"
                    @phone-number="phoneChange"
                  />
                  <LegalNameComponent
                    v-if="item.slot == 'legal-name'"
                    @first-name="onChangeFirstName"
                    @last-name="onChangeLastName"
                  >
                    <template #legalNameaButtons>
                      <v-card-actions
                        v-if="checkHasToShowLegalNameButtons(item)"
                      >
                        <v-btn
                          :width="198"
                          ripple
                          block
                          large
                          class="heading heading-s-small white--text primary shadow-dark-button"
                          @click="submitForm"
                        >
                          {{ item.btnPrimaryText }}
                        </v-btn>
                      </v-card-actions>
                      <v-card-actions
                        v-if="checkHasToShowLegalNameButtons(item)"
                      >
                        <v-btn
                          :width="198"
                          ripple
                          block
                          large
                          class="heading heading-s-small primary--text  shadow-dark-button"
                          @click="goBackToRegister"
                        >
                          {{ item.btnOptionalText }}
                        </v-btn>
                      </v-card-actions>
                    </template>
                  </LegalNameComponent>

                  <ConfirmCodeComponent
                    v-if="item.slot == 'confirm-code'"
                    :suggest-resend-code="suggestResendCode"
                    @full-code="fullCodeChange"
                    @resend-code="resendCode"
                    @change-number="goBackToChangeNumber"
                  >
                    <template #continue>
                      <v-card-actions
                        v-if="checkHasToShowConfirmCodeSection(item)"
                      >
                        <v-btn
                          :width="198"
                          ripple
                          block
                          large
                          class="heading heading-s-small white--text primary shadow-dark-button"
                          @click="submitForm"
                        >
                          {{ item.btnOptionalText }}
                        </v-btn>
                      </v-card-actions>
                    </template>
                  </ConfirmCodeComponent>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                :class="containerActionsClass"
              >
                <v-col cols="12">
                  <v-row
                    v-if="item.tab === enumOptions.mainView"
                    no-gutters
                  >
                    <v-col class="checkbox-agree-emails">
                      <slot name="agree-emails" />
                    </v-col>
                  </v-row>
                  <v-col 
                    cols="12"
                    class="buttons-actions"
                  >
                    <v-card-actions
                      v-if="item.tab === enumOptions.mainView && item.slot != 'password-reset' "
                      class="px-0"
                    >
                      <v-btn
                        :width="198"
                        ripple
                        block
                        large
                        class="heading heading-s-small white--text shadow-dark-button"
                        :class="item.tab === enumOptions.mainView ? 'cold-gradient' : 'primary'"
                        data-test-id="submit"
                        @click="submitForm"
                      >
                        {{ item.btnPrimaryText }}
                      </v-btn>
                    </v-card-actions>

                    <v-card-actions
                      v-if="checkHasToShowSubmitButton(item)"
                      class="px-0"
                    >
                      <v-btn
                        :width="198"
                        ripple
                        block
                        large
                        class="heading heading-s-small white--text shadow-dark-button"
                        :class="item.tab === enumOptions.mainView ? 'cold-gradient' : 'primary'"
                        data-test-id="submit"
                        @click="submitForm"
                      >
                        {{ item.btnPrimaryText }}
                      </v-btn>
                    </v-card-actions>

                    <v-card-actions
                      v-if="type===enumType.register && item.tab===enumRegister.verifyPhoneNumber && item.slot != 'password-reset'"
                      class="px-0"
                    >
                      <v-btn
                        :width="198"
                        ripple
                        block
                        large
                        color="white"
                        class="heading heading-s-small primary--text shadow-dark-button"
                        @click="goBackToLegalName"
                      >
                        {{ item.btnOptionalText }}
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions
                      v-if="item.tab == enumOptions.mainView"
                      class="theme-container-actions px-0 mb-lg-3"
                    >
                      <v-btn
                        :width="198"
                        ripple
                        block
                        large
                        color="white"
                        class="heading heading-s-small primary--text shadow-light-button mx-0"
                        @click="authSocialite('google')"
                      >
                        <GoogleIconComponent class="mr-2" />
                        {{ googleBtnText }}
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                  <slot name="forgot-password" />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-form>
        </v-tabs-items>
      </v-flex>
    </template>
  </ContainerComponent>
</template>

<script>
import ContainerComponent from '@/components/common/ContainerComponent';
import EmailPasswordComponent from '@/components/common/EmailPasswordComponent';
import ForgotPasswordComponent from '@/components/common/ForgotPasswordComponent';
import LegalNameComponent from  '@/components/common/LegalNameComponent';
import PhoneComponent from  '@/components/common/PhoneComponent';
import ConfirmCodeComponent from  '@/components/common/ConfirmCodeComponent';
import GoogleIconComponent from '@/assets/GoogleIconComponent';
import { mapActions } from 'vuex';
export default {
  components: { EmailPasswordComponent, ContainerComponent, ForgotPasswordComponent, LegalNameComponent, PhoneComponent, ConfirmCodeComponent, GoogleIconComponent },
  props: ['items', 'type', 'itemTab', 'phoneNumber', 'firstName', 'lastName'],
  data: () => ({
    tab: 0,
    form: {
      email: null,
      password: null,
    },
    suggestResendCode: false,
    enumOptions: { mainView: 0 },
    enumRegister: { register: 0, legalName: 1, verifyPhoneNumber: 2, confirmCode: 3, forgotPassword: 4 },
    enumType: { login: "login", register: "register", passwordReset: "password-reset" },
    sent: true,
  }),
  computed: {
    googleBtnText() {
      return 'Sign up with Google';
    },
    signupOrLoginText() {
      return this.type === 'login' ? 'Sign up' : 'Login';
    },
    containerActionsClass() {
      return this.type === 'login' ? 'container-actions-login mb-sm-3' : 'container-actions-register mb-sm-3';
    },
    titleResponsiveSize() {
      if (this.$vuetify.breakpoint.width <= 320) {
        return "heading heading-m-small"
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "heading heading-m-large";
        case "sm":
        case "lg":
          return "heading heading-l-large";
        default:
          return "heading heading-l-small";
      }
    },
    showPhoneNumberLastDigits() {
      return this.phoneNumber?.slice(-3);
    }
  },
  watch: {
    itemTab: function (newValue) {
      this.tab = newValue;
    }
  },
  methods: {
    ...mapActions('auth', ['sendConfirmationCode']),
    checkHasToShowMaskedPhoneNumber(item) {
      if(item.tab > this.enumOptions.mainView && this.type===this.enumType.register && item.tab===this.enumRegister.confirmCode) return true;
    },
    checkHasToShowLegalNameButtons(item) {
      if(this.type===this.enumType.register && item.tab===this.enumRegister.legalName && item.slot !== this.enumType.passwordReset) return true;
    },
    checkHasToShowConfirmCodeSection(item) {
      if(this.type===this.enumType.register && item.tab===this.enumRegister.confirmCode && item.slot!==this.enumType.passwordReset) return true;
    },
    checkHasToShowSubmitButton(item) {
      if(item.tab !==this.enumOptions.mainView && item.slot !== this.enumType.passwordReset && item.tab!==this.enumRegister.legalName && item.tab !== this.enumRegister.confirmCode) return true;
    },
    onChangeFirstName(newValue) { 
      this.form.firstName = newValue
      this.$emit('first-name-changed', this.form);
    },
    onChangeLastName(newValue) { 
      this.form.lastName = newValue
      this.$emit('last-name-changed', this.form);
    },
    emailChange(newEmail) {
      this.form.email = newEmail;
      this.$emit('credentials-changed', this.form);
    },
    passwordChange(newPassword) { 
      this.form.password = newPassword;
      this.$emit('credentials-changed', this.form);
    },
    phoneChange(newPhone) {
      this.$emit('phone-change', newPhone);
    },
    async resendCode(newPhone) {
      const response = await this.sendConfirmationCode({phone_number: this.phoneNumber});

      if(response.data.isValid){
        this.$toasted.global.showSuccess({message: response.data.message});
        this.sent = true;
      }else{
        this.$toasted.global.showError({message: response.data.message});
      }
    },
    fullCodeChange(newFullCode) {
      this.fullCode = newFullCode;
      this.$emit('full-code', this.fullCode);
    },
    submitForm() {
      this.$refs.auth_form.validate();
      this.$emit('perform-main-action', this.form, false);
    },
    goToLoginOrRegister() {
      if(this.type === "login") {
        this.$router.push('register');
        } else {
        this.$router.push('login');
      }
    },
    passwordReset() {
      this.tab = this.enumOptions.mainView;
    },
    authSocialite(provider) {

      let self = this;
      this.$auth.authenticate(provider).then(response => {

        if(this.type === 'login') {
          self.socialLogin(provider,response);
        } else {
          self.socialRegister(provider,response);
        }
      }).catch(err => {
        console.log({err:err});
      });
    },
    socialLogin(provider,response) {
      this.$http.post('/socialite/'+provider, response).then(socialiteResponse => {
        if (socialiteResponse.data?.success) {
          this.$emit('perform-main-action', null, true);
        }
      }).catch(this.handleSocialError);
    },
    socialRegister(provider,response) {
      this.$http.post('/socialite/register/'+provider, response).then(socialiteResponse => {
        this.form.email = socialiteResponse.data.userToRegister.email;
        this.form.password = socialiteResponse.data.userToRegister.password;
        this.$emit('credentials-changed', this.form);
        this.$emit('social-register',  socialiteResponse.data.userToRegister );
        this.$emit('perform-main-action', this.form, true);
      }).catch(this.handleSocialError);
    },
    handleSocialError(err) {
      this.$toasted.global.showError({
        message: err.response.data?.message ?? "An unexpected error happend.",
      });
    },
    goBackToLogin(data) {
      this.$emit("goBackToLogin", data);
    },
    goBackToRegister() {
      this.$emit("goBackToRegister", this.enumRegister.register);
    },
    goBackToLegalName() {
      this.$emit("goBackToRegister", this.enumRegister.legalName);
    },
    goBackToChangeNumber(data) { 
      this.$emit("goBackToRegister", data);
    }
  },
};
</script>

<style>
.container-actions-login {
  bottom: 15px;
  margin-left: 0px;
  width: 100%;
}

.container-actions-register {
  bottom: 5px;
  margin-left: 0px;
  width: 100%;
}

.buttons-actions {
  padding: 0 0 12px 0;
}

@media (max-width: 320px) {
  .checkbox-agree-emails .v-input__slot {
    align-items: flex-start !important;
  }
}

@media (min-width: 760px) and (max-width: 999px) {
  .buttons-actions {
    padding: 0 0 4px 0;
  }

}

@media (min-width: 1265px) and (max-width: 1439px) and (orientation:landscape) { 
  .container-actions-login {
    bottom: 30px;
    margin-left: 0px;
    width: 100%;
  }
}

@media (min-width: 1440px)  and (orientation: landscape) { 
  .buttons-actions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 0 10px 0;
 }
}

.theme-container-actions {
  flex-wrap: wrap;
}

.theme-button {
  border-radius: 10px !important;
}

.forgot-password-btn:hover {
  cursor: pointer;
}

.forgot-password-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  color: #1da1f2;
}
</style>
<style scoped>
button {
  letter-spacing: 0.5px !important;
}
</style>
