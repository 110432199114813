<template>
  <v-row>
    <v-col 
      cols="12" 
      class="pb-0"
    >
      <div class="text-left">
        <label class="text text-m-small-bold">Legal first name</label>
        <v-text-field
          v-model="firstName"
          height="56"
          dense
          name="firstName"
          class="field-background-white"
          outlined
          placeholder="Legal first name"
          :rules="[rules.required, rules.names]"
          required
        />
        <label class="text text-m-small-bold">Legal last name</label>
        <v-text-field
          v-model="lastName"
          height="56"
          dense
          name="lastName"
          outlined
          class="field-background-white"
          placeholder="Legal last name"
          :rules="[rules.required, rules.lastName]"
          required
        />
      </div>
    </v-col>
    <v-row>
      <v-col 
        cols="12"
        class="actions"
      >
        <slot 
          name="legalNameaButtons" 
          class="container-actions" 
        />
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import ValidatorComponent from '@/components/common/ValidatorComponent';
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ValidatorComponent },
  data: () => ({
    firstName: '',
    lastName: '',
    rules: ValidatorComponent.data().rules,
  }),
  watch: {
    firstName: function (newName) {
      this.$emit('first-name', newName);
    },
    lastName: function (newName) {
      this.$emit('last-name', newName);
    },
  },
}
</script>
<style scoped>
  .field-background-white >>> input::placeholder {
    color: var(--v-grey-darken4) !important;
  }

  .v-input >>> .v-input__append-inner {
    margin-top: 0 !important;
    align-self: center !important;
  }

  .v-text-field--outlined >>> fieldset {
    border: 1px solid var(--v-grey-darken4);
    background-color: transparent !important;
  }

  .field-background-white >>> .v-icon {
    color: var(--v-grey-darken4) !important;
  }

  .actions {
    padding: 18px;
  }

  .container-actions {
    flex-wrap: wrap;
    bottom: 15px;
    margin-left: 0px;
    width: 100%;
  }

  @media (min-width: 1440px)  and (orientation: landscape) {
    .actions {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
</style>
