<template>
  <div class="text-left">
    <label class="text text-m-small-bold">Email</label>
    <v-text-field
      v-model="localEmail"
      height="56"
      dense
      outlined
      class="field-background-white"
      name="email"
      :messages="emailTaken"
      placeholder="Enter your email"
      :rules="[rules.required, rules.email]"
      required
      data-test-id="email"
    />
    <PasswordComponent
      :label="'Password'"
      :placeholder="'Enter your password'"
      @new-password="newPassword"
    />
  </div>
</template>

<script>
  import ValidatorComponent from '@/components/common/ValidatorComponent';
  import PasswordComponent from '@/components/common/PasswordComponent';
  export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { ValidatorComponent, PasswordComponent },
    props: [ 'isNewEmail', 'email' ],
    data: () => ({
      localEmail: '',
      rules: ValidatorComponent.data().rules,
    }),
    computed:{
      emailTaken(){
        return !this.isNewEmail ? 'Looks like this email is already taken. Did you Forget Password?' : '';
      }
    },
    watch: {
      localEmail: function (newEmail) {
        this.$emit('email', newEmail);
      },
    },
    methods: {
      newPassword(newPassword) {
        this.$emit('password', newPassword);
      }
    },
  }
</script>
<style scoped>

  .field-background-white >>> input::placeholder {
    color: var(--v-grey-darken4) !important;
  }

  .field-background-white fieldset {
    border: 1px solid #F1F1F5;
    background-color: var(--v-background-base) !important;
  }

  .border-radius-15 {
    border-radius: 15px;
  }

  .v-label {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.1px !important;
    color: #92929D !important;
  }

  input {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.1px !important;
    color: #92929D !important;
  }

  .v-text-field--outlined >>> fieldset {
    border: 1px solid var(--v-grey-darken4);
    background-color: transparent !important;
  }

  .v-text-field--outlined.v-input--has-state >>> fieldset {
    border: 2px solid currentColor;
  }
</style>
